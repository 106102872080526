@use '@/styles/mixins/typography' as *;
@use '@/styles/tokens/colors' as *;
@use '@/styles/animation' as *;

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  &::before,
  &::after {
    box-sizing: inherit;
  }
}

html,
body,
#__next {
  height: 100%;
  @include typography();
  font-size: 62.5%;
}

body {
  &:has([data-overlay='true']) {
    overflow: hidden;
  }
}

h1,
h2,
h3,
h4,
h5,
h6,
span,
p,
a {
  text-decoration: none;
  color: $color-neutral-dark;
}

ul {
  list-style: none;
}

button {
  cursor: pointer;
  border: none;
  background: transparent;
  font-family: inherit;

  * {
    pointer-events: none;
  }
}

textarea {
  font-family: 'Open Sans', sans-serif;
}

.grecaptcha-badge {
  visibility: hidden;
}
