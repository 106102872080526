@mixin keyframes($name) {
  @-webkit-keyframes #{$name} {
    @content;
  }
  @-moz-keyframes #{$name} {
    @content;
  }
  @-ms-keyframes #{$name} {
    @content;
  }
  @keyframes #{$name} {
    @content;
  }
}

@mixin animation(
  $animation,
  $duration,
  $timing: ease,
  $delay: unset,
  $fill: none,
  $count: none
) {
  $name: #{$animation}-#{unique-id()};

  @keyframes #{$name} {
    @content;
  }

  animation-name: $name;
  animation-duration: $duration;
  animation-timing-function: $timing;
  animation-delay: $delay;
  animation-fill-mode: $fill;
  animation-iteration-count: $count;
}

@mixin fade-in(
  $duration,
  $timing: ease,
  $delay: none,
  $fill: none,
  $count: none
) {
  @include animation('fade-in', $duration, $timing, $delay, $fill, $count) {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
}
@mixin floatCard($duration, $delay: none) {
  @include animation('float', $duration, ease-in-out, $delay, none, infinite) {
    0% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-8px);
    }
    100% {
      transform: translateY(0);
    }
  }
}
@mixin floatScore($duration, $delay: none) {
  @include animation('float', $duration, ease-in-out, $delay, none, infinite) {
    0% {
      transform: translateY(0) scale(1);
    }
    50% {
      transform: translateY(-8px) scale(1.1);
    }
    100% {
      transform: translateY(0) scale(1);
    }
  }
}
@mixin backgroundAnimation($colors) {
  $previousColor: nth($colors, 1);
  $nextColor: nth($colors, 2);

  @include animation(
    $animation: backgroundgFillingAnimation,
    $duration: 2s,
    $fill: forwards
  ) {
    0% {
      background-position: -200% 0;
      background-image: linear-gradient(
        105deg,
        $previousColor 50%,
        $nextColor 50%
      );
    }

    100% {
      background-position: 0 0;
      background-image: linear-gradient(105deg, $nextColor 50%, $nextColor 50%);
    }
  }
}

@mixin graphicFillingAnimation() {
  @include animation(
    $animation: graphicFilling,
    $duration: 2s,
    $fill: forwards
  ) {
    0% {
      transform: rotate(15deg) scale(1.5) translateX(0);
    }
    100% {
      transform: rotate(15deg) scale(1.5) translateX(-100%);
    }
  }
}

@mixin firstImageSlideAnimation($name) {
  @include keyframes($name) {
    0% {
      opacity: 0;
      transform: translateX(350px);
    }
    100% {
      opacity: 1;
      transform: translateX(-30px);
    }
  }

  animation: $name;
  animation-duration: 1s;
  animation-fill-mode: forwards;
}
@mixin secondImageHeroAnimation($name) {
  @include keyframes($name) {
    0% {
      opacity: 0;
      transform: translateX(350px);
    }
    100% {
      opacity: 1;
      transform: translateX(-120px);
    }
  }

  animation: $name;
  animation-duration: 1s;
  animation-fill-mode: forwards;
}
@mixin imageHeroAnimation($name) {
  @include keyframes($name) {
    0% {
      opacity: 0;
      transform: translateX(350px);
    }
    100% {
      opacity: 1;
      transform: translateX(-30px);
    }
  }

  animation: $name;
  animation-duration: 1s;
  animation-fill-mode: forwards;
}
@mixin contentHeroAnimation($name) {
  @include keyframes($name) {
    0% {
      opacity: 0;
      transform: translateX(-500px);
    }
    100% {
      opacity: 1;
      transform: translateX(0);
    }
  }

  animation: $name;
  animation-duration: 1s;
  animation-fill-mode: forwards;
}

@mixin heroImageAnimation($name) {
  @include keyframes($name) {
    from {
      opacity: 0;
      left: 80%;
      transform: translateX(-80%);
    }
    to {
      opacity: 1;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  animation: $name;
  animation-duration: 1s;
  animation-fill-mode: forwards;
}
@mixin floatCardHero($duration, $delay: none) {
  @include animation('float', $duration, ease-in-out, $delay, none, infinite) {
    0% {
      transform: translateY(0);
      opacity: 0;
    }
    50% {
      transform: translateY(-8px);
      opacity: 1;
    }
    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }
}

@mixin grow() {
  @include animation('grow', 300ms, ease) {
    from {
      transform: scale(0);
    }

    to {
      transform: scale(1);
    }
  }
}

@mixin horizontalScroll() {
  @include animation('horizontalScroll', 8s, linear, 0s, forwards, infinite) {
    from {
      transform: translateX(100%);
    }

    to {
      transform: translateX(-100%);
    }
  }
}
