@use '@/styles/mixins' as *;

[data-animate='fade-up'][data-animate='fade-up'].animate-init {
  opacity: 0;
  transition-duration: 0.6s;
  transition-timing-function: ease;
  transition-property: opacity, transform;
  transform: translate3d(0, 80px, 0);
}

[data-animate='zoom-out'][data-animate='zoom-out'].animate-init {
  opacity: 0;
  transition-duration: 0.6s;
  transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
  transition-property: opacity, transform;
  transform: scale(1.2);
}

[data-animate='fade-up'][data-animate='fade-up'].animate {
  opacity: 1;
  transform: translateZ(0);
}

[data-animate='zoom-out'][data-animate='zoom-out'].animate {
  opacity: 1;
  transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
  transform: translateZ(0) scale(1);
}

[data-animate='fade-in'] {
  opacity: 0;
}

[data-animate='fade-in'][data-animate='fade-in'].animate {
  @include fade-in(0.3s, ease-in, 0.2s, forwards);
}

[data-animate][data-animate][data-animate-delay='100'].animate {
  transition-delay: 0.1s;
}

[data-animate][data-animate][data-animate-delay='200'].animate {
  transition-delay: 0.2s;
}

[data-animate][data-animate][data-animate-delay='300'].animate {
  transition-delay: 0.3s;
}
